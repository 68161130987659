import { Container } from "theme-ui"

const Article = ({ children, semantic }) => {
  return (
    <>
      <Container
        as={semantic ? "article" : "div"}
        variant={"wide"}
        sx={{ mb: "6rem" }}
      >
        {children}
      </Container>
    </>
  )
}

Article.defaultProps = {
  semantic: true
}

export default Article
