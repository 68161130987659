import { Container } from "theme-ui"

const Section = ({ heading, padding, sticky, semantic, children }) => {
  return (
    <>
      <Container
        as={semantic ? "section" : "div"}
        variant={sticky ? "sticky" : "regular"}
        sx={padding && { pb: ["1rem", null, null, null, "2rem"] }}
      >
        {heading && <h2 sx={{ variant: "text.hidden" }}>{heading}</h2>}
        {children}
      </Container>
    </>
  )
}

Section.defaultProps = {
  heading: null,
  padding: false,
  sticky: false,
  semantic: true
}

export default Section
