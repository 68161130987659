import { Link } from "gatsby"
import { Article, Section } from "../components/containers"
import { ArticleTitle } from "../components/headings"
import { Paragraph } from "../components/text"
import { NotFoundUl } from "../components/lists"
import { ListLink } from "../components/links"

import { useProsePosts } from "../hooks/use-prose-posts"

const NotFoundPage = () => {
  const { allChapters } = useProsePosts()
  const chapter = allChapters[allChapters.length - 1].node

  return (
    <Article>
      <ArticleTitle title="404 — Page Not Found" />
      <Section semantic={false}>
        <Paragraph>
          Apologies! The page you seek cannot be found at this address. It may
          have been removed or relocated. Please double-check the url, or else
          peruse the links below.
        </Paragraph>
        <NotFoundUl>
          <ListLink link={chapter.slug} label={"Current Chapter"} />
          <ListLink link={"/story/"} label={"Table of Contents"} />
        </NotFoundUl>
        <Paragraph>
          Alternatively, <Link to="/" sx={{variant: "links.body", fontFeatureSettings: "'kern' 1, 'liga' 1, 'onum' 1, 'pnum' 1, 'case' 1, 'smcp' 1",}}>return to the homepage</Link>.
        </Paragraph>
      </Section>
    </Article>
  )
}

export default NotFoundPage
